import { dollarFormat, shortDollars } from "./dollarUtils";

export const formatGrade = (value?: number | string, dollars?: number) => {
  return `${value ?? "-"} | ${dollars ? shortDollars(dollars) : "-"}`;
};

export const formatGradeTuple = (
  value?: number | string | null,
  dollars?: number | null,
  gradeScalingFactor?: number
) => {
  if (value == null) return "--";

  const safeGradeScalingFactor =
    typeof gradeScalingFactor === "number" && gradeScalingFactor !== 0
      ? gradeScalingFactor
      : 1;

  const isValueNumeric = !isNaN(Number(value));
  const numericValue = isValueNumeric
    ? Number(value) / safeGradeScalingFactor
    : null;
  const formattedValue = numericValue !== null ? numericValue.toFixed(2) : "-";

  const isDollarsNumeric = dollars !== null && !isNaN(Number(dollars));
  const formattedDollars = isDollarsNumeric
    ? dollarFormat(Number(dollars))
    : "-";

  return `${formattedValue} | ${formattedDollars}`;
};

/**
 * Creates a valueGetter and sortComparator for comparing two values in a MUI DataGridPremium component
 * Meant for sorting player grades by their raw grade value then dollar value
 */
export const gradeDollarComparator = (
  gradeValue?: string | number | undefined,
  dollarValue?: string | number | undefined
) => ({
  valueGetter: () => {
    const grade = gradeValue || 0;
    const dollars = dollarValue || 0;
    // Concatenate the values so they can be split apart in the sortComparator
    return `${grade}-${dollars}`;
  },
  sortComparator: (v1: string, v2: string) => {
    const [grade1, dollars1] = v1.split("-").map(Number);
    const [grade2, dollars2] = v2.split("-").map(Number);

    // Sort by raw grade
    if (grade1 !== grade2) {
      return grade1 - grade2;
    }

    // Then compare by dollars
    return dollars1 - dollars2;
  },
});

import {
  Badge,
  Checkbox,
  Drawer,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

import { Button, ButtonSize, ButtonType } from "../../../common/button/Button";
import React from "react";
import _ from "lodash";
import { getSideOfBall } from "../../../../utils/clubStaticData";

export const PositionFilterDrawer = (props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  filterOptions: string[];
  positionFilters: string[];
  setPositionFilters: (filters: string[]) => void;
  top?: boolean;
  loading?: boolean;
}) => {
  const theme = useTheme();
  const colors = theme.palette;
  const {
    open,
    setOpen,
    positionFilters,
    setPositionFilters,
    loading,
    top = false,
  } = props;

  // In-memory filters before Applying
  const [selectedFilters, setSelectedFilters] = React.useState(positionFilters);
  const handleFilterChange = (value: string) => {
    if (selectedFilters.includes(value)) {
      setSelectedFilters(selectedFilters.filter((filter) => filter !== value));
    } else {
      setSelectedFilters([...selectedFilters, value]);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setSelectedFilters(positionFilters);
    onClose();
  };

  const handleClear = () => {
    setSelectedFilters([]);
    // return grid to default state
    setPositionFilters([]);
    setOpen(false);
  };

  const handleApply = () => {
    setPositionFilters(selectedFilters);
    setOpen(false);
  };

  const applyDisabled = _.isEqual(
    _.sortBy(positionFilters),
    _.sortBy(selectedFilters)
  );

  const clearDisabled = selectedFilters.length === 0;

  return (
    <>
      <div
        style={{
          position: "relative",
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        <Button
          buttonType={ButtonType.Secondary}
          buttonSize={ButtonSize.ExtraSmall}
          onClick={() => setOpen(true)}
          disabled={loading}
          sx={{
            marginRight: "5px",
            fontSize: "15px",
            height: 36,
            marginTop: "12px",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              fontSize={15}
              fontWeight={400}
              color={colors.grey[600]}
              mr={0.5}
              mt={0.5}
              fontFamily={"massilia-variable"}
              data-testid="grid-filter-button"
            >
              Filters
            </Typography>
            <FilterListIcon
              sx={{ color: colors.grey[600], transform: "scale(0.9)" }}
            />
          </Stack>
        </Button>
        {selectedFilters.length > 0 && (
          <Badge
            badgeContent={selectedFilters.length}
            color="primary"
            sx={{
              position: "absolute",
              top: 10,
              right: 8,
              transform: "translate(70%, -70%)",
              display: "flex",
              flexDirection: "row",
            }}
          />
        )}
      </div>

      <Drawer
        open={open}
        anchor={"right"}
        hideBackdrop={true}
        onClose={onClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: "25%",
            top: top ? undefined : "64px", // start at the bottom of the draft dashboard header
            zIndex: 20,
          },
        }}
      >
        <Stack>
          <DrawerSection>
            <Typography fontSize={18} fontWeight={500}>
              Filters
            </Typography>
            <Button
              buttonType={ButtonType.Secondary}
              buttonSize={ButtonSize.Small}
              onClick={handleCancel}
              data-testid="cancel-filters-btn"
            >
              Cancel
            </Button>
          </DrawerSection>

          <GridPositionFilter
            handleFilterChange={handleFilterChange}
            selectedFilters={selectedFilters}
            filterOptions={props.filterOptions}
          />

          <DrawerSection
            sx={{
              backgroundColor: theme.palette.grey[50],
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Button
              buttonType={ButtonType.Tertiary}
              onClick={handleClear}
              disabled={clearDisabled}
              data-testid="clear-filters-btn"
            >
              Clear All Filters
            </Button>
            <Button dark onClick={handleApply} disabled={applyDisabled} data-testid="apply-filters-btn">
              Apply Filters
            </Button>
          </DrawerSection>
        </Stack>
      </Drawer>
    </>
  );
};

const GridPositionFilter = (props: {
  filterOptions: string[];
  selectedFilters?: string[];
  handleFilterChange: (pos: string) => void;
}) => {
  const { filterOptions, selectedFilters, handleFilterChange } = props;

  const offense = filterOptions.filter((p) => getSideOfBall(p) === "Offense");
  const defense = filterOptions.filter((p) => getSideOfBall(p) === "Defense");
  const specialTeams = filterOptions.filter(
    (p) => getSideOfBall(p) === "Special Teams"
  );

  const other = filterOptions.filter(
    (p) =>
      !offense.includes(p) && !defense.includes(p) && !specialTeams.includes(p)
  );

  const createPositionChunks = (positions: string[]) => {
    const chunkSize = 4;
    const chunks = [];
    for (let i = 0; i < positions.length; i += chunkSize) {
      const chunk = positions.slice(i, i + chunkSize);
      chunks.push(chunk);
    }
    const positionStacks = chunks.map((chunk, index) =>
      chunk.map((pos) => (
        <PositionCheckbox key={`${pos}-${index}`} position={pos} />
      ))
    );
    return positionStacks;
  };

  const PositionSection = (props: {
    title: string;
    positionGroup?: string[];
  }) => {
    const theme = useTheme();
    const { title, positionGroup } = props;

    if (!positionGroup) return null;

    const positionChunks = createPositionChunks(positionGroup);

    return (
      <Stack direction="column" ml={0.5} mt={2}>
        <Typography
          fontSize={14}
          fontWeight={600}
          color={theme.palette.grey[700]}
          ml={1}
        >
          {title}
        </Typography>
        <Stack>
          {positionChunks.map((chunk, index) => (
            <Stack key={index} direction="row" alignItems="center">
              {chunk}
            </Stack>
          ))}
        </Stack>
      </Stack>
    );
  };

  const PositionCheckbox = (props: { position: string }) => {
    const { position } = props;
    return (
      <Stack key={position} direction="row" alignItems="center" width="60px">
        <Checkbox
          checked={selectedFilters?.includes(position)}
          onChange={() => handleFilterChange(position)}
          size="small"
          sx={{ size: "20px" }}
        />
        <Typography fontSize="14px" fontWeight="400px" marginLeft="-7px">
          {position}
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      <DrawerSection>
        <Typography fontSize={14} fontWeight={600}>
          By Position
        </Typography>
      </DrawerSection>

      <Stack direction="column" flex={1} justifyContent="space-between" mb={1}>
        <>
          <PositionSection title="Offense" positionGroup={offense} />
          <PositionSection title="Defense" positionGroup={defense} />
          <PositionSection title="Special Teams" positionGroup={specialTeams} />
          {other.length > 0 && (
            <PositionSection title="Other" positionGroup={other} />
          )}
        </>
      </Stack>
    </>
  );
};

export const DrawerSection = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "16px 12px 16px 12px",
  borderBottom: `2px solid ${theme.palette.grey[100]}`,
}));

type SeasonType = "REGULAR_SEASON" | "PRESEASON" | "POSTSEASON" | "BOWL_GAME";

const SeasonTypeMap: Record<SeasonType, string> = {
  REGULAR_SEASON: "Regular Season",
  PRESEASON: "Pre Season",
  POSTSEASON: "Post Season",
  BOWL_GAME: "Bowl Game",
};

export const getSeasonType = (seasonType?: string | null) => {
  if (seasonType && seasonType in SeasonTypeMap) {
    return SeasonTypeMap[seasonType as SeasonType];
  } else {
    return seasonType;
  }
};

export const ROW_COLOR = "#f7f8fa";

import React, { useState, useEffect } from "react";
import Image, { ImageProps, StaticImageData } from "next/image";
import * as Sentry from "@sentry/nextjs";

const ImageWithFallback = (
  props: {
    src?: string | StaticImageData;
    missingImage: string | StaticImageData;
  } & ImageProps
) => {
  const { src, missingImage, ...rest } = props;
  const [imgSrc, setImgSrc] = useState(src);
  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  if (!src) {
    return (
      <div style={{ height: props.height, width: props.width }}>
        <Image {...rest} src={missingImage} alt="image" />
      </div>
    );
  }

  return (
    <div style={{ height: props.height, width: props.width }}>
      <Image
        {...rest}
        src={imgSrc}
        onLoadingComplete={(result) => {
          if (result.naturalWidth === 0) {
            // Broken image
            setImgSrc(missingImage);
          }
        }}
        onError={() => {
          if (typeof src == "string") {
            Sentry.captureException(
              new Error(`ImageWithFallback.onError src=${src}`)
            );
          } else {
            Sentry.captureException(
              new Error(`ImageWithFallback.onError src.src=${src.src}`)
            );
          }
          setImgSrc(missingImage);
        }}
        alt="image"
      />
    </div>
  );
};

export default ImageWithFallback;

import { useTheme } from "@mui/material";
import { ROW_COLOR } from "@sumer/shared/components/players/stats/SeasonType";

// MUI DataGridPremium doesn't seem to support real MUI theming
// so this approach was the best alternative for reusable styles
export const useDataGridStyles = () => {
  const theme = useTheme();
  return {
    ".MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "&.MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
    "& .MuiDataGrid-cell": {
      borderBottomColor: theme.palette.grey[200],
    },
    "& .MuiDataGrid-main": {
      border: theme.border.primary,
      borderRadius: theme.borderRadius.medium,
      boxShadow: theme.boxShadow,
    },
    "& .MuiDataGrid-footerContainer": {
      border: "none",
    },
    "& .header-styling, & .column-header": {
      backgroundColor: theme.palette.common.white,
    },
    "& .header-styling:focus-within, & .column-header:focus-within": {
      outline: "none",
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "white",
      borderBottomColor: theme.palette.grey[200],
    },
    "& .MuiDataGrid-row": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      background: theme.palette.common.white,
      width: "16px",
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
      background: theme.palette.common.white,
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      background: "#babac0",
      borderRadius: "16px",
      border: `4px solid ${theme.palette.common.white}`,
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
    ".highlight": {
      bgcolor: ROW_COLOR,
      "&:hover": {
        bgcolor: theme.palette.grey[50],
      },
    },
  };
};

import { SearchRounded } from "@mui/icons-material";
import { Box, Stack, Theme, Typography, useTheme } from "@mui/material";
import {
  GridToolbarQuickFilter,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid-premium";
import { ColumnsIcon } from "../icons/ColumnsIcon";

export const GridQuickFilterToolbar = ({
  setColumnsButtonEl,
  searchText,
  showColumnEdit,
  FilterComponent,
  showSearch = true,
  showTitle,
  titleText,
}: {
  setColumnsButtonEl?: React.Dispatch<React.SetStateAction<null>>;
  setFilterButtonEl?: React.Dispatch<React.SetStateAction<null>>;
  showColumnEdit?: boolean;
  showSearch?: boolean;
  searchText?: string;
  showTitle?: boolean;
  titleText?: string;
  FilterComponent?: React.ReactNode | undefined; // The Filter component
}) => {
  const theme = useTheme();

  const SearchBar = () => (
    <Stack
      display="flex"
      flexDirection="row"
      border={theme.border.primary}
      borderRadius={theme.borderRadius.medium}
      boxShadow={theme.boxShadow.medium}
      marginTop={2}
      sx={{
        backgroundColor: theme.palette.common.white,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="center" pl={1} >
        <SearchRounded style={{ width: "20px" }} />
      </Box>

      <GridToolbarQuickFilter
        data-testid="grid-player-search"
        sx={{
          width: "300px",
          height: "36px",
          padding: "1px",
        }}
        quickFilterParser={(searchInput: string) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
        InputProps={{
          disableUnderline: true,
          style: {
            fontSize: "17px",
            fontWeight: 400,
            color: theme.palette.grey[500],
          },
          placeholder: searchText || "Player Search",
        }}
      />
    </Stack>
  );

  return (
    <Stack
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="end"
      margin="0 0px 15px 0px"
    >
      <Stack>
        {/* Grid Title */}
        {showTitle && (
          <Typography
            fontSize={16}
            fontWeight={400}
            fontFamily={"massilia-variable"}
            color={theme.palette.textColor.light}
          >
            {titleText}
          </Typography>
        )}
        {/* Search Bar */}
        {showSearch && <SearchBar />}
      </Stack>

      {/* Buttons */}
      <Stack
        flex={1}
        flexDirection="row"
        justifyContent="end"
        alignItems="center"
      >
        {/* FILTER component for the grid */}
        {FilterComponent}
        {showColumnEdit && (
          <GridToolbarColumnsButton
            color="inherit"
            ref={setColumnsButtonEl as React.Ref<HTMLButtonElement>}
            sx={{
              ...toolbarBtnStyles(theme),
              marginRight: "5px",
              fontSize: "15px",
              height: "36px",
              width: "146px",
            }}
            startIcon={null}
            endIcon={
              <ColumnsIcon
                sx={{
                  color: theme.palette.grey[600],
                  transform: "scale(0.9)",
                  size: "20px",
                  marginTop: "-5px",
                }}
              />
            }
          />
        )}
      </Stack>
    </Stack>
  );
};

export const toolbarBtnStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.common.white,
  fontFamily: "massilia-variable",
  padding: "9px 12px 4px 12px",
  marginRight: "5px",
  fontSize: "15px",
  height: "36px",
  width: "146px",
  marginTop: "12px",
  minHeight: 24,
  minWidth: 78,
  fontWeight: 500,
  borderRadius: "4px",
  boxShadow: `0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)`,
  color: theme.palette.grey[600],
  border: `1px solid ${theme.palette.grey[100]}`,
  textTransform: "none",
  justifyContent: "center",
  alignItems: "center",
  "&:hover": {
    color: theme.palette.grey[900],
    border: `1px solid ${theme.palette.grey[100]}`,
    backgroundColor: theme.palette.grey[50],
  },
  "&.Mui-disabled": {
    color: theme.palette.grey[900],
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[100]}`,
  },
});

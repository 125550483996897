import { SvgIcon, SxProps } from "@mui/material";
import React from "react";

export const PlayerOutlineIcon = (props: { sx?: SxProps }) => {
  return (
    <SvgIcon sx={{ ...props.sx }}>
      <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3332 13C11.3332 12.0696 11.3332 11.6044 11.2183 11.2259C10.9598 10.3736 10.2929 9.70669 9.4406 9.44816C9.06207 9.33333 8.59688 9.33333 7.6665 9.33333H4.33317C3.4028 9.33333 2.93761 9.33333 2.55908 9.44816C1.70681 9.70669 1.03986 10.3736 0.78133 11.2259C0.666504 11.6044 0.666504 12.0696 0.666504 13M8.99984 4C8.99984 5.65685 7.65669 7 5.99984 7C4.34298 7 2.99984 5.65685 2.99984 4C2.99984 2.34315 4.34298 1 5.99984 1C7.65669 1 8.99984 2.34315 8.99984 4Z"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

import { Tabs as MUITabs, Tab, styled } from "@mui/material";

export enum TabStyle {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
}

interface TabProps {
  tabs: string[];
  currentTab: string;
  setCurrentTab: (tab: string) => void;
  style?: TabStyle;
}

export const Tabs = ({
  tabs,
  currentTab,
  setCurrentTab,
  style = TabStyle.Primary,
}: TabProps) => {
  const value = tabs.indexOf(currentTab);
  const tabIndicatorProps = { style: { display: "none" } };
  const onChange = (_e: React.SyntheticEvent, newValue: number) =>
    setCurrentTab(tabs[newValue]);

  switch (style) {
    case TabStyle.Secondary:
      return (
        <SecondaryTabs
          value={value}
          TabIndicatorProps={tabIndicatorProps}
          onChange={onChange}
          data-testid="workspace-tabs"
        >
          {tabs.map((tab, index) => (
            <SecondaryTab key={index} label={tab} data-testid={`workspace-secondary-tab-${tab.replace(' ', '-')}`} />
          ))}
        </SecondaryTabs>
      );

    case TabStyle.Tertiary:
      return (
        <TertiaryTabs
          value={value}
          TabIndicatorProps={tabIndicatorProps}
          onChange={onChange}

        >
          {tabs.map((tab, index) => (
            <TertiaryTab key={index} label={tab} data-testid={`workspace-tertiary-tab-${tab.replace(' ', '-')}`} />
          ))}
        </TertiaryTabs>
      );

    default:
      return (
        <MUITabs
          value={value}
          TabIndicatorProps={tabIndicatorProps}
          onChange={onChange}
        >
          {tabs.map((tab, index) => (
            <PrimaryTab key={index} label={tab} data-testid={`workspace-primary-tab-${tab.replace(' ', '-')}`} />
          ))}
        </MUITabs>
      );
  }
};

const PrimaryTab = styled(Tab)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
  borderColor: theme.palette.primary.main,
  borderStyle: "solid",
  borderBottomWidth: 1,
  marginLeft: 30,
  minWidth: 60,
  textTransform: "none",
  minHeight: "10px",
  lineHeight: "10px",
  marginTop: 4,
  ":hover": {
    backgroundColor: theme.palette.grey[200],
  },
  "&.Mui-selected": {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.primary.main,
    borderWidth: "1px 1px 0px 1px",
  },
}));

const SecondaryTabs = styled(MUITabs)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: "8px",
  alignItems: "center",
  width: "fit-content",
  minHeight: "fit-content",
  padding: "4px 0",
  boxShadow: "0px 1px 3px 0px #1018281A",
  border: "1px solid",
  borderColor: theme.palette.grey[200],
}));

const SecondaryTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 700,
  fontSize: 16,
  color: theme.palette.grey[500],
  borderRadius: "4px",
  padding: "0 8px",
  margin: "0 4px",
  minHeight: "40px",
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    opacity: 1,
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
  },
}));

const TertiaryTabs = styled(SecondaryTabs)(() => ({
  boxShadow: "none",
  border: "none",
  borderColor: "none",
}));

const TertiaryTab = SecondaryTab;

import { Tooltip } from "@mui/material";

export const TooltipForGrids = ({
  title,
  headerName,
  placement,
}: {
  title: string;
  headerName: string;
  placement?: "top" | "bottom" | "left" | "right";
}): JSX.Element => {
  return (
    <Tooltip
      title={
        <span style={{ fontSize: "13px", fontWeight: "400" }}>{title}</span>
      }
      placement={placement}
      arrow
      data-testid="grid-header-tooltip"
    >
      <span style={{ fontWeight: "500" }}>{headerName}</span>
    </Tooltip>
  );
};

// Home Page
export const CLICKED_START_SIMULATION = "clicked-start-simulation";
export const VISITED_SETTINGS = "visited-settings";

// Club IP
export const VISITED_PLAYER_GRADES = "visited-player-grades";
export const VISITED_OTHER_TEAM_NEEDS = "visited-other-team-needs";
export const UPDATED_GRADE_WEIGHTS = "updated-grade-weights";

// Scenario List
export const VISITED_DRAFT_SCENARIOS = "visited-draft-scenarios";
export const VIEWED_SAVED_SCENARIO = "viewed-saved-scenario";
export const VIEWED_COMPLETED_SCENARIO = "viewed-completed-scenario";

// Upcoming Pick Tab
export const VISITED_UPCOMING_PICK_TAB = "visited-upcoming-pick-tab";

// Selected Pick Tab
export const VISITED_SELECTED_PICK_TAB = "visited-selected-pick-tab";

// Trades
export const VISITED_TRADE_EVALUATION = "visited-trade-evaluation";
export const VISITED_TRADE_DATABASE = "visited-trade-database";
export const CLICKED_PIN_TRADE = "clicked-pin-trade";
export const CLICKED_EXECUTE_TRADE = "clicked-execute-trade";
export const CLICKED_EVALUATE_TRADE = "clicked-evaluate-trade";

// Pick Panel
export const CLICKED_MY_PICKS = "clicked-my-picks";
export const CLICKED_TRADE_OPPS = "clicked-trade-opps";

// Draft Analytics
export const CLICKED_EXPAND_ANALYTICS = "clicked-expand-analytics";
